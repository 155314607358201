var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[`class-${_vm.name}`, {'carousel-default-wrapper': _vm.setDefaultClass}]},[_c('div',{staticClass:"body-slider"},[_c('div',{class:[
        _vm.isAbsolute && !_vm.$data.$$isMobile ? 'absolute absolute__left' : '',
        (_vm.isBanner && _vm.size === 1) || _vm.size < 2 || _vm.isHideButton ? 'hideButton' : '',
        'body-slider__button',
        'body-slider__button__left'
      ],style:({margin: _vm.marginSlider}),on:{"click":function($event){return _vm.prevPage()}}},[_c('img',{attrs:{"src":'/images/slider-' + _vm.colorSlider + '-left.svg',"alt":"slider-left.svg","height":"100%","width":"100%"}})]),_vm._v(" "),_c('div',{staticClass:"slider",on:{"mouseover":_vm.pause,"mouseleave":_vm.slideShow}},[_c('div',{staticClass:"slides",style:(_vm.isDefineShimmer()),attrs:{"id":"slides-wrapper"}},[_vm._t("item")],2)]),_vm._v(" "),_c('div',{class:[
        _vm.isAbsolute && !_vm.$data.$$isMobile ? 'absolute absolute__right' : '',
        (_vm.isBanner && _vm.size === 1) || _vm.size < 2 || _vm.isHideButton ? 'hideButton' : '',
        'body-slider__button', 
        'body-slider__button__right'
      ],style:({margin: _vm.marginSlider}),on:{"click":function($event){return _vm.nextPage()}}},[_c('img',{attrs:{"src":'/images/slider-' + _vm.colorSlider + '-right.svg',"alt":"slider-right.svg","height":"100%","width":"100%"}})])]),_vm._v(" "),(_vm.size > 1)?_c('div',{style:({textAlign: _vm.sliderDotPosition}),attrs:{"id":"slider-dots"}},_vm._l((_vm.size),function(slide){return _c('span',{key:slide + 'key',staticClass:"slide-dots",class:{'active-slider': slide === 1},style:(_vm.isDefineDot()),attrs:{"id":`dots-${_vm.name}-${slide}`},on:{"click":function($event){return _vm.scrollToPosition(slide)}}})}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }