/**
 *
 * @param array {[]}
 * @param perChunk {number}
 * @returns {[][]}
 */
const chunkArray = (array, perChunk) => {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};

/**
 *
 * @returns {{TABLET: string, DESKTOP: string, MOBILE: string}}
 * @constructor
 */
const MEDIA = () => {
  return {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    TABLET: 'tablet',
  };
};

/**
 *
 * @param width {number}
 * @returns {string}
 */
const generateChunkCarouselResponsive = width => {
  if (width >= 768 && width <= 1024) {
    return MEDIA().TABLET;
  } else if (width >= 220 && width <= 766) {
    return MEDIA().MOBILE;
  } else {
    return MEDIA().DESKTOP;
  }
};

/**
 *
 * @param func {Function}
 */
const listenerWindowResize = func => {
  window.addEventListener('resize', func);
};

/**
 *
 * @param func {Function}
 */
const removeListenerResize = func => {
  window.removeEventListener('resize', func);
};

module.exports = {
  chunkArray,
  MEDIA,
  generateChunkCarouselResponsive,
  listenerWindowResize,
  removeListenerResize,
};
