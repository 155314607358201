<template>
  <div :class="[`class-${name}`, {'carousel-default-wrapper': setDefaultClass}]">
    <div class="body-slider">
      <div
        :class="[
          isAbsolute && !$data.$$isMobile ? 'absolute absolute__left' : '',
          (isBanner && size === 1) || size < 2 || isHideButton ? 'hideButton' : '',
          'body-slider__button',
          'body-slider__button__left'
        ]"
        :style="{margin: marginSlider}"
        @click="prevPage()"
      >
        <img
          :src="'/images/slider-' + colorSlider + '-left.svg'"
          alt="slider-left.svg"
          height="100%"
          width="100%"
        >
      </div>
      <div
        class="slider"
        @mouseover="pause"
        @mouseleave="slideShow"
      >
        <div
          id="slides-wrapper"
          class="slides"
          :style="isDefineShimmer()"
        >
          <slot name="item" />
        </div>
      </div>
      <div
        :class="[
          isAbsolute && !$data.$$isMobile ? 'absolute absolute__right' : '',
          (isBanner && size === 1) || size < 2 || isHideButton ? 'hideButton' : '',
          'body-slider__button', 
          'body-slider__button__right'
        ]"
        :style="{margin: marginSlider}"
        @click="nextPage()"
      >
        <img
          :src="'/images/slider-' + colorSlider + '-right.svg'"
          alt="slider-right.svg"
          height="100%"
          width="100%"
        >
      </div>
    </div>
    <div
      v-if="size > 1"
      id="slider-dots"
      :style="{textAlign: sliderDotPosition}"
    >
      <span
        v-for="slide in size"
        :id="`dots-${name}-${slide}`"
        :key="slide + 'key'"
        class="slide-dots"
        :class="{'active-slider': slide === 1}"
        :style="isDefineDot()"
        @click="scrollToPosition(slide)"
      />
    </div>
  </div>
</template>

<script>
import {
  generateChunkCarouselResponsive, listenerWindowResize, MEDIA, removeListenerResize,
} from './util.js';

export default {
  name: 'Carousel',
  props: {
    shimmer: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      default: Math.random().toString(36).slice(-5),
    },
    // total array in multidimensional
    totalAllData: {
      type: Number,
      required: true,
    },
    customClassSlides: {
      type: String,
      default: 'slides',
    },
    colorSlider: {
      type: String,
      default: 'red',
    },
    sliderDotPosition: {
      type: String,
      default: 'start',
    },
    showDot: {
      type: Boolean,
      default: true,
    },
    marginSlider: {
      type: String,
      default: 'auto 20px',
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    autoPlayInterval: {
      type: Number,
      default: 2000,
    },
    scrollFocus: {
      type: Boolean,
      default: true,
    },
    isBanner: {
      type: Boolean,
      default: false,
    },
    isHideButton: {
      type: Boolean,
      default: false,
    },
    setDefaultClass: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorNotChoose: '#D4D5D8',
      colorChosen: '#B82025',
      classActiveSlider: 'active-slider',
      classParentSlider: 'slides',
      offShimmer: '-webkit-mask-image: none;',
      offDot: 'display: none;',
      $$isMobile: false,
      isTablet: false,
      widthDot: 'initial',
      isClickNextOrPrev: false,
      interval: null,
    };
  },
  computed: {
    getIsClickNextOrPrev: {
      get() {
        return this.isClickNextOrPrev;
      },
      set(val) {
        this.isClickNextOrPrev = val;
      },
    },
  },
  mounted() {
    this.listenerMobileResize();
    this.$el.querySelector(`.${this.classParentSlider}`).addEventListener('scroll', this.listenerEvent, { passive: true });
    this.$el.querySelector(`.${this.classParentSlider}`).addEventListener('touchmove', this.listenerEventTouch, { passive: true });
    listenerWindowResize(this.listenerMobileResize);
    if (this.autoPlay) this.slideShow();
  },
  beforeDestroy() {
    removeListenerResize(this.listenerMobileResize);
    this.$el.querySelector(`.${this.classParentSlider}`).removeEventListener('scroll', this.listenerEvent);
    this.$el.querySelector(`.${this.classParentSlider}`).removeEventListener('touchmove', this.listenerEventTouch);
  },
  methods: {
    pause() {
      if (this.autoPlay) clearInterval(this.interval);
    },
    slideShow() {
      if (this.autoPlay) {
        this.interval = setInterval(() => {
          if (+this.getIDSlider() === this.size) {
            this.goToSlider(1);
            this.scrollToPosition(1);
          } else this.nextPage(+this.getIDSlider());
        }, this.autoPlayInterval);
      }
    },
    /**
     *
     * @param id {number} // number of index length data
     */
    goToSlider(id) {
      const parentSliderWrapper = this.$el.querySelector(`.${this.classParentSlider}`);
      if (id > this.size) {
        id = this.size;
      }
      this.$el
        .querySelectorAll(`.slide-dots:not([dots-${this.name}-${id}])`)
        .forEach((el) => {
          el.style.backgroundColor = this.colorNotChoose;
          el.classList.remove(this.classActiveSlider);
        });
      const activeDot = this.$el.querySelector(`#dots-${this.name}-${id}`);
      if (activeDot) {
        activeDot.style.backgroundColor = this.colorChosen;
        activeDot.classList.add(this.classActiveSlider);
      }
      if (id === this.size) {
        parentSliderWrapper.setAttribute('style', this.offShimmer);
      } else if (!this.shimmer) {
        parentSliderWrapper.setAttribute('style', this.offShimmer);
      } else {
        parentSliderWrapper.setAttribute('style', '');
      }
    },
    /**
     *
     * @param id {number} // number of index length data
     */
    scrollToPosition(id) {
      this.getIsClickNextOrPrev = false;
      const slider = this.$el.querySelector('#slides-wrapper');
      const childSlider = this.$el.querySelector(`#${this.name}-${id}`);
      if (childSlider) {
        this.goToSlider(id);
        if (!this.scrollFocus) slider.scroll({ behavior: 'smooth', left: childSlider.offsetLeft });
        else childSlider.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
      }
    },
    isDefineShimmer() {
      let newStyle = '';
      if (!this.shimmer) {
        newStyle += this.offShimmer;
      }

      if (this.totalAllData <= 3 && !this.$$isMobile && !this.isBanner && !this.setDefaultClass) {
        newStyle += '; justify-content: center; ';
      }
      return newStyle;
    },
    isDefineDot() {
      let newStyle = '';
      if (!this.showDot) {
        newStyle += this.offDot;
      }
      return newStyle;
    },
    /**
     *
     * @returns {string}
     */
    getIDSlider() {
      const active = this.$el.querySelector(`.${this.classActiveSlider}`);
      if (active) {
        const id = active.id.split('-');
        return id[id.length - 1];
      }
      return '1';
    },
    prevPage() {
      this.getIsClickNextOrPrev = false;
      if (+this.getIDSlider() > 1) {
        this.scrollToPosition(+this.getIDSlider() - 1);
      }
    },
    nextPage() {
      this.getIsClickNextOrPrev = false;
      if (+this.getIDSlider() !== this.size) {
        this.scrollToPosition(+this.getIDSlider() + 1);
      }
      if (+this.getIDSlider() === this.size) {
        this.$emit('lastPage', true);
      }
    },
    listenerEventTouch() {
      this.getIsClickNextOrPrev = true;
    },
    _goToSliderConditions() {
      let currentIndex = -1;
      const wrap = `.class-${this.name}`; // the parent of wrapper child
      const items = this.$el.querySelectorAll(wrap);
      for (let i = 0; i < items.length; i++) {
        if (items[i].getBoundingClientRect().left + window.scrollX >= 0 && currentIndex === -1) {
          currentIndex = i;
        }
      }
      if (this.size > 2) {
        this.goToSlider(currentIndex + 1);
      } else if (currentIndex === 0) {
        this.goToSlider(currentIndex + 1);
      } else {
        this.goToSlider(currentIndex);
      }
    },
    listenerEvent() {
      if ((this.$$isMobile || this.isTablet) && this.getIsClickNextOrPrev) {
        if (this.size > 1) {
          this.$el.querySelector('#slider-dots .slide-dots').style.backgroundColor = this.colorNotChoose;
        }
        this._goToSliderConditions();
      }
    },
    listenerMobileResize() {
      this.$$isMobile = generateChunkCarouselResponsive(window.innerWidth) === MEDIA().MOBILE;
      this.isTablet = generateChunkCarouselResponsive(window.innerWidth) === MEDIA().TABLET;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
